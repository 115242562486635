/**
 * Flimmo API V.2
 * Flimmo API V.2 ist der Nachfolger der XML-Datenschnitstelle
 *
 * The version of the OpenAPI document: 2.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Source } from './source';


/**
 * This is a generic preview item, which is used for Swimlane.items, Frontpage.hero/.special, Besprechung.similaritems, SearchResponse.result
 */
export interface PreviewItem { 
    id: number;
    itemclass: PreviewItem.ItemclassEnum;
    title: string;
    /**
     * One-line summary for this item
     */
    summary?: string;
    /**
     * Only for optional `Frontpage.special`, short content text from the Special
     */
    content?: string;
    genre?: string;
    /**
     * 1: Rot, 2: Gelb, 3: Grün, 4: Grün + Prädikat
     */
    rating?: number;
    /**
     * main high resolution image (for highres previews, e.g. Frontpage.hero)
     */
    imagehigh?: string;
    /**
     * main low resolution image (for small previews, e.g. swimlane)
     */
    imagelow?: string;
    /**
     * copyright information for the main image
     */
    imagec?: string;
    topos?: string;
    altersfreigabe?: number;
    /**
     * unique list of sources where this media can be watched, e.g. \"Netflix, RTL\"
     */
    srclist?: Array<Source>;
}
export namespace PreviewItem {
    export type ItemclassEnum = 'besprechung' | 'ytbesprechung' | 'redtext' | 'special' | 'feature' | 'social_media';
    export const ItemclassEnum = {
        Besprechung: 'besprechung' as ItemclassEnum,
        Ytbesprechung: 'ytbesprechung' as ItemclassEnum,
        Redtext: 'redtext' as ItemclassEnum,
        Special: 'special' as ItemclassEnum,
        Feature: 'feature' as ItemclassEnum,
        SocialMedia: 'social_media' as ItemclassEnum
    };
}


